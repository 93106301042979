.card {
   @apply h-full rounded-[14px] flex flex-col bg-white transition duration-300 w-full pb-4 max-w-full relative overflow-hidden shadow-md;
   @apply w-full max-w-[360px];
   .img {
      @apply w-full object-cover rounded-[14px] rounded-b-md aspect-w-3 aspect-h-2 min-h-full h-[229px];
   }
   .title {
      @apply font-bold text-lg text-primary-color block hover:underline mt-2 line-clamp-2 break-words;
   }
   .img-container {
      @apply overflow-hidden mb-2 relative aspect-w-3 aspect-h-2 h-[229px] rounded-default;
      span,
      div {
         @apply !h-full;
      }
   }

   &.small {
      @apply border-none h-auto;
      .img {
         @apply h-[180px];
      }
      .title {
         @apply mb-1 min-h-0;
      }
      .img-container {
         height: 180px;
      }
   }
}
