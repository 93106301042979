.bg1 {
   background: url('/images/kafu/fifa.png') bottom left no-repeat;
   @media (max-width: 768px) {
      background-size: 100px auto;
   }
   background-size: 300px auto;
}
.bg2 {
   background: url('/images/kafu/pubg.png') bottom right no-repeat;
   @media (max-width: 768px) {
      background-size: 70px auto;
   }
   background-size: 350px auto;
}
