.card {
   @apply rounded-default h-full  flex flex-col relative font-medium bg-white min-h-[120px];
   @apply justify-center items-center px-4 py-3 text-center hover:bg-gray-100;
   box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06);

   &.active {
      @apply bg-gray-200 shadow-none;
      &::after {
         content: '';
         @apply w-0 h-0 absolute;
         border-left: 18px solid transparent;
         border-right: 18px solid transparent;
         border-top: 12px solid theme('colors.gray.200');
         border-radius: 15px;
         animation: come-down 200ms ease-in-out forwards;
      }
   }
}
.card-mobile {
   @apply rounded-default h-full flex flex-col;
   @apply justify-center items-center px-4 py-3 text-center;
}

.shadow {
   box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.08);
}

@keyframes come-down {
   from {
      bottom: 0;
   }
   to {
      bottom: -10px;
   }
}
