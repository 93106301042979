.card_card__4A7Pu {
}

.card_card__8tU29 {
         position: relative;
         display: flex;
         height: 100%;
         width: 100%;
         max-width: 100%;
         flex-direction: column;
         overflow: hidden;
         border-radius: 14px;
         --tw-bg-opacity: 1;
         background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
         padding-bottom: 1rem;
         --tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
         box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
         transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter;
         transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter;
         transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter, -webkit-backdrop-filter;
         transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
         transition-duration: 150ms;
         transition-duration: 300ms;
         width: 100%;
         max-width: 360px;
}
   .card_card__8tU29 .card_img__qJ5In {
         height: 229px;
}
   .card_card__8tU29 .card_img__qJ5In {
         min-height: 100%;
}
   .card_card__8tU29 .card_img__qJ5In {
         width: 100%;
}
   .card_card__8tU29 .card_img__qJ5In {
         border-radius: 14px;
}
   .card_card__8tU29 .card_img__qJ5In {
         border-bottom-right-radius: 0.375rem;
         border-bottom-left-radius: 0.375rem;
}
   .card_card__8tU29 .card_img__qJ5In {
         -o-object-fit: cover;
            object-fit: cover;
}
   .card_card__8tU29 .card_img__qJ5In {
         position: relative;
         padding-bottom: calc(var(--tw-aspect-h) / var(--tw-aspect-w) * 100%);
}
   .card_card__8tU29 .card_img__qJ5In > * {
         position: absolute;
         height: 100%;
         width: 100%;
         top: 0;
         right: 0;
         bottom: 0;
         left: 0;
}
   .card_card__8tU29 .card_img__qJ5In {
         --tw-aspect-w: 3;
}
   .card_card__8tU29 .card_img__qJ5In {
         --tw-aspect-h: 2;
}
   .card_card__8tU29 .card_title__Jc1VP {
         margin-top: 0.5rem;
}
   .card_card__8tU29 .card_title__Jc1VP {
         display: block;
}
   .card_card__8tU29 .card_title__Jc1VP {
         overflow-wrap: break-word;
}
   .card_card__8tU29 .card_title__Jc1VP {
         font-size: 1.125rem;
         line-height: 1.75rem;
}
   .card_card__8tU29 .card_title__Jc1VP {
         font-weight: 700;
}
   .card_card__8tU29 .card_title__Jc1VP {
         --tw-text-opacity: 1;
         color: rgba(33, 37, 41, var(--tw-text-opacity));
}
   .card_card__8tU29 .card_title__Jc1VP {
         overflow: hidden;
         display: -webkit-box;
         -webkit-box-orient: vertical;
         -webkit-line-clamp: 2;
}
   .card_card__8tU29 .card_title__Jc1VP:hover {
         text-decoration: underline;
}
   .card_card__8tU29 .card_img-container__iYYbM {
         position: relative;
}
   .card_card__8tU29 .card_img-container__iYYbM {
         margin-bottom: 0.5rem;
}
   .card_card__8tU29 .card_img-container__iYYbM {
         height: 229px;
}
   .card_card__8tU29 .card_img-container__iYYbM {
         overflow: hidden;
}
   .card_card__8tU29 .card_img-container__iYYbM {
         border-radius: 14px;
}
   .card_card__8tU29 .card_img-container__iYYbM {
         position: relative;
         padding-bottom: calc(var(--tw-aspect-h) / var(--tw-aspect-w) * 100%);
}
   .card_card__8tU29 .card_img-container__iYYbM > * {
         position: absolute;
         height: 100%;
         width: 100%;
         top: 0;
         right: 0;
         bottom: 0;
         left: 0;
}
   .card_card__8tU29 .card_img-container__iYYbM {
         --tw-aspect-w: 3;
}
   .card_card__8tU29 .card_img-container__iYYbM {
         --tw-aspect-h: 2;
}
   .card_card__8tU29 .card_img-container__iYYbM span, .card_card__8tU29 .card_img-container__iYYbM div {
         height: 100% !important;
}
   .card_card__8tU29.card_small__XZmUQ {
         height: auto;
}
   .card_card__8tU29.card_small__XZmUQ {
         border-style: none;
}
   .card_card__8tU29.card_small__XZmUQ .card_img__qJ5In {
         height: 180px;
}
   .card_card__8tU29.card_small__XZmUQ .card_title__Jc1VP {
         margin-bottom: 0.25rem;
}
   .card_card__8tU29.card_small__XZmUQ .card_title__Jc1VP {
         min-height: 0px;
}
   .card_card__8tU29.card_small__XZmUQ .card_img-container__iYYbM {
         height: 180px;
      }

.card-link_card__xmL0V {
   position: relative;
   display: flex;
   height: 100%;
   min-height: 120px;
   flex-direction: column;
   border-radius: 14px;
   --tw-bg-opacity: 1;
   background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
   font-weight: 500;
   align-items: center;
   justify-content: center;
   padding-left: 1rem;
   padding-right: 1rem;
   padding-top: 0.75rem;
   padding-bottom: 0.75rem;
   text-align: center;
}.card-link_card__xmL0V:hover {
   --tw-bg-opacity: 1;
   background-color: rgba(243, 244, 246, var(--tw-bg-opacity));
}.card-link_card__xmL0V {
   box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06);
}.card-link_card__xmL0V.card-link_active__2Hlfz {
   --tw-bg-opacity: 1;
   background-color: rgba(229, 231, 235, var(--tw-bg-opacity));
}.card-link_card__xmL0V.card-link_active__2Hlfz {
   --tw-shadow: 0 0 #0000;
   box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}.card-link_card__xmL0V.card-link_active__2Hlfz::after {
         content: '';
      }.card-link_card__xmL0V.card-link_active__2Hlfz::after {
   position: absolute;
}.card-link_card__xmL0V.card-link_active__2Hlfz::after {
   height: 0px;
}.card-link_card__xmL0V.card-link_active__2Hlfz::after {
   width: 0px;
}.card-link_card__xmL0V.card-link_active__2Hlfz::after {
         border-left: 18px solid transparent;
         border-right: 18px solid transparent;
         border-top: 12px solid #e5e7eb;
         border-radius: 15px;
         animation: card-link_come-down__c_Fgb 200ms ease-in-out forwards;
      }
.card-link_card-mobile__o6e9E {
   display: flex;
   height: 100%;
   flex-direction: column;
   border-radius: 14px;
   align-items: center;
   justify-content: center;
   padding-left: 1rem;
   padding-right: 1rem;
   padding-top: 0.75rem;
   padding-bottom: 0.75rem;
   text-align: center;
}

.card-link_shadow__JwgiJ {
   box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.08);
}

@keyframes card-link_come-down__c_Fgb {
   from {
      bottom: 0;
   }
   to {
      bottom: -10px;
   }
}

.styles_top-banner__AdsV6 {
   position: relative;
   display: flex;
   justify-content: center;
   padding-left: 1.5rem;
   padding-right: 1.5rem;
}@media (min-width: 768px) {.styles_top-banner__AdsV6 {
      align-items: center;
   }
}.styles_top-banner__AdsV6 {
   height: 612px;
   /* clip-path: ellipse(170% 100% at 50% 0%); */
}/* background: linear-gradient(0deg, rgba(0,0,0,0.00) 45%, rgba(0,0,0,0.72) 100%); */@media (min-width: 1600px) {.styles_top-banner__AdsV6 {
      font-size: 20px;
}
   }
.styles_overlay__vKzfe {
   position: absolute;
   top: 0px;
   right: 0px;
   bottom: 0px;
   left: 0px;
}
@media (min-width: 540px) {.styles_overlay__vKzfe {
      border-radius: 20px;
   }
}
.styles_overlay__vKzfe {
   background: linear-gradient(180deg, rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 0.81) 100%);
}

.styles_map__m1Fti {
   width: 100%;
   max-width: 100%;
   border-radius: 0.375rem;
   -o-object-fit: cover;
      object-fit: cover;
}

@media (min-width: 1280px) {.styles_map__m1Fti {
      width: auto;
   }
}

.styles_map__m1Fti {
   min-height: 242px;
}

.styles_upcoming__RiIKz {
   margin-bottom: 6rem;
   --tw-bg-opacity: 1;
   background-color: rgba(13, 21, 156, var(--tw-bg-opacity));
   background-repeat: no-repeat;
   padding-top: 3rem;
   padding-bottom: 3rem;
   --tw-text-opacity: 1;
   color: rgba(255, 255, 255, var(--tw-text-opacity));
}

@media (min-width: 1024px) {

.styles_upcoming__RiIKz {
      background-position: 110%;
   }
   }

.styles_section-radius__obuvs {
   border-bottom-right-radius: 50% 25px;
   border-bottom-left-radius: 50% 25px;
}

.styles_category-grid__UJr6C {
   grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
}

.styles_category-card__NTWt6 svg {
      transition: all 0.5s ease;
   }

.styles_category-card__NTWt6:hover svg {
         transform: scale(3);
         transition: all 0.5s ease;
      }

/* .carousel-container {
   width: 100%;
   @apply hidden lg:block  mx-auto;
}

@media (min-width: 2850px) {
   .carousel-container {
       {
         max-width: 2850px;
      }
   }
} */

.styles_cities-overlay__V3PDA {
   position: absolute;
   top: 0px;
   right: 0px;
   bottom: 0px;
   left: 0px;
   border-radius: 14px;
   background: linear-gradient(180deg, rgba(0, 0, 0, 0) 29%, #000000 100%);
}

.styles_rectangle__D9QIN {
   margin-bottom: 2rem;
   height: 18rem;
   flex-direction: column;
   align-items: center;
   overflow: hidden;
   border-radius: 0.5rem;
   border-width: 2px;
   --tw-border-opacity: 1;
   border-color: rgba(254, 243, 199, var(--tw-border-opacity));
   --tw-bg-opacity: 1;
   background-color: rgba(255, 251, 235, var(--tw-bg-opacity));
   padding-left: 1rem;
   padding-right: 1rem;
}@media (min-width: 768px) {.styles_rectangle__D9QIN {
      padding-top: 6rem;
   }
}.styles_rectangle__D9QIN {
   border-bottom-right-radius: 50% 20px;
   border-bottom-left-radius: 50% 20px;
}
@media (min-width: 768px) {.styles_text__0PaPv {
      padding-left: 0px;
      padding-right: 0px;
   }
}
@media (min-width: 1024px) {.styles_text__0PaPv {
      padding-left: 2.5rem;
      padding-right: 2.5rem;
   }
}
@media (min-width: 1280px) {.styles_text__0PaPv {
      padding-left: 3rem;
      padding-right: 3rem;
   }
}
.styles_text__0PaPv {
   transform: translate(0, -460px);
}

.styles_bg1__pQvOv {
   background: url('/images/kafu/fifa.png') bottom left no-repeat;
   background-size: 300px auto;
}
   @media (max-width: 768px) {.styles_bg1__pQvOv {
      background-size: 100px auto;
}
   }
.styles_bg2__of4tn {
   background: url('/images/kafu/pubg.png') bottom right no-repeat;
   background-size: 350px auto;
}
@media (max-width: 768px) {
.styles_bg2__of4tn {
      background-size: 70px auto;
}
   }

