.top-banner {
   @apply relative flex justify-center md:items-center px-6;
   /* background: linear-gradient(0deg, rgba(0,0,0,0.00) 45%, rgba(0,0,0,0.72) 100%); */
   @media (min-width: 1600px) {
      font-size: 20px;
   }
   height: 612px;
   /* clip-path: ellipse(170% 100% at 50% 0%); */
}
.overlay {
   @apply absolute inset-0 xs:rounded-xl;
   background: linear-gradient(180deg, rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 0.81) 100%);
}

.map {
   @apply w-full xl:w-auto object-cover rounded-md max-w-full;
   min-height: 242px;
}

.upcoming {
   @apply py-12 text-white bg-primary-blue bg-no-repeat mb-24;
   @screen lg {
      background-position: 110%;
   }
}

.section-radius {
   border-bottom-right-radius: 50% 25px;
   border-bottom-left-radius: 50% 25px;
}

.category-grid {
   grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
}

.category-card {
   svg {
      transition: all 0.5s ease;
   }
   &:hover {
      svg {
         transform: scale(3);
         transition: all 0.5s ease;
      }
   }
}

/* .carousel-container {
   width: 100%;
   @apply hidden lg:block  mx-auto;
}

@media (min-width: 2850px) {
   .carousel-container {
       {
         max-width: 2850px;
      }
   }
} */

.cities-overlay {
   @apply absolute inset-0 rounded-default;
   background: linear-gradient(180deg, rgba(0, 0, 0, 0) 29%, #000000 100%);
}
